import ProductTable from './ProductTable'
import NewTable from './NewTable';
import './App.css';

function App() {

  return (
    <div className="App">
      <>
        <h1>Our Products</h1>
        <NewTable />
      </>
    </div>
  );
}

export default App;
